

















































import Vue from 'vue'
import {
  BAlert,
  BCard,
  BLink,
  BOverlay,
} from 'bootstrap-vue'

import EmailTemplateForm from '@/views/admin/emails/email-forms/EmailTemplateForm.vue'
import { appEmailItem } from '@/store/modules/app-email-item'

export default Vue.extend({
  name: 'EmailItem',
  components: {
    BAlert,
    BCard,
    BLink,
    BOverlay,

    EmailTemplateForm,
  },
  computed: {
    isLoading() {
      return appEmailItem.getters.getIsLoading
    },
    emailTemplate() {
      return appEmailItem.getters.getEmailTemplate
    },
    isButtonDisabled() {
      return appEmailItem.getters.getIsButtonDisabled
    },
  },
  async created() {
    await this.setEmailTemplate()
  },
  beforeDestroy() {
    appEmailItem.mutations.RESET_EMAIL_TEMPLATE()
  },
  methods: {
    async setEmailTemplate() {
      const emailTemplateID = this.$router.currentRoute.params.id
      if (emailTemplateID) {
        await appEmailItem.actions.setEmailTemplate(emailTemplateID)
      }
    },
    async updateTemplate() {
      await appEmailItem.actions.updateTemplate()
    },
    goBack() {
      this.$router.push({ name: 'admin-email-templates-folders' })
    },
  },
})
